import React from 'react';
import ReactGA from 'react-ga4';
import {
    Button,
    Col,
    Modal, ModalBody,
    Row,
    Table,
    UncontrolledTooltip,
    ModalHeader,
    FormGroup,
    Label,
    InputGroup,
    ModalFooter,
    Input
} from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import Ads from './Ads';
import {SubscriptionLevels} from "../../constants/subscriptionLevels";
import Footer from '../../components/Footer';
import {USER_TYPE} from "../../constants/userTypes";
import {renameListing, roundedMertic, defaultListingImages, getImageUrl} from '../../components/util';
import {EmailShareButton, FacebookShareButton, TwitterShareButton, LinkedinShareButton} from 'react-share'
import {Line} from 'react-chartjs-2';
// import chartjs from 'chart.js';
import { Chart as chartjs} from "chart.js/auto";
import ScrollBooster from 'scrollbooster';
import NewChat from '../../components/NewChat/NewChat'
import Textarea from 'react-expanding-textarea'
// import 'bootstrap/dist/css/bootstrap.min.css';

import UpgradeSubscriptionModal from "../UpgradeSubscriptionModal/UpgradeSubscriptionModal";
import {withRouter} from "../withRouter/withRouter";
import Header from "../../components/Header";
import {Helmet} from "react-helmet";
import {AuthContext} from "../../contexts/auth";

// chartjs.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale);
chartjs.defaults.backgroundColor = '#9BD0F5';
chartjs.defaults.elements.line.fill = '#9BD0F5';

class DealPage extends React.Component {
    captcha;
    BrokerForm;
    img;

    constructor(props) {
        super(props);
        this.state = {
            deal_id: props.match.params.dealId,
            url: props.location.pathname,
        }

        this.ContactBroker = this.ContactBroker.bind(this);
    }

    handleContactBrokerValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        //fullName
        if (!fields['fullName']) {
            formIsValid = false;
            errors['fullName'] = 'Please enter full name.';
        }
        //Email
        if (!fields['email']) {
            formIsValid = false;
            errors['email'] = 'Please enter email address.';
        }
        if (typeof fields['email'] !== 'undefined') {
            let lastAtPos = fields['email'].lastIndexOf('@');
            let lastDotPos = fields['email'].lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields['email'].indexOf('@@') === -1 && lastDotPos > 2 && (fields['email'].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors['email'] = 'Please enter valid email address.';
            }
        }
        //message
        if (!fields['message']) {
            formIsValid = false;
            errors['message'] = 'Please enter your message.';
        }
        if (typeof fields['phonenumber'] !== 'undefined') {
            if (!fields['phonenumber'].match(/^(?=.*[0-9]).{14,14}$/)) {
                formIsValid = false;
                errors['phonenumber'] = 'Please enter phone no (555) 555-5555 format.';
            }
        }
        if (!this.state.agree) {
            formIsValid = false;
            errors['agree'] = 'Please select I have read and agree to the Terms of Use and Privacy Policy.';
        }
        //Captcha
        if (!this.state.captchavalue) {
            formIsValid = false;
            errors['captcha'] = 'Please select captcha.';
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleChangeContactBroker(field, e) {
        let newFields = this.state.fields;
        if (e.target.id === 'agree') {
            if (this.state.agree) {
                this.setState({agree: 0});
            } else {
                this.setState({agree: 1});
            }
        }
        if (e.target.id === 'newsletter') {
            if (this.state.newsletter) {
                this.setState({newsletter: 0});
            } else {
                this.setState({newsletter: 1});
            }
        }
        if (e.target.id === 'acquisition') {
            if (this.state.acquisition) {
                this.setState({acquisition: 0});
            } else {
                this.setState({acquisition: 1});
            }
        }
        newFields[field] = e.target.value;
        if (e.target.name === 'phonenumber') {
            let phonenumber = e.target.value.replace(/[^0-9]/g, '');
            phonenumber = phonenumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
            newFields[field] = phonenumber;
        }
        this.setState({fields: newFields});
    }

    onChangeCaptcha = (value) => {
        this.setState({captchavalue: value})
    }
    resetcaptcha = () => {
        this.captcha.reset();
    }

    ContactBroker(e) {
        e.preventDefault();
        if (this.handleContactBrokerValidation()) {
            document.getElementById('ContactBroker').setAttribute('style', 'display: block;');
            let fields = this.state.fields;
            let phonenumber = '';
            if (fields['phonenumber'] !== undefined) {
                phonenumber = fields['phonenumber'];
            } else {
                phonenumber = '';
            }
            let jsonBrokerContactFormData = JSON.stringify({
                'name': fields['fullName'],
                'email': fields['email'],
                'phone': phonenumber,
                'subject': fields['subject'],
                'newsletter': this.state.newsletter,
                'finance': this.state.acquisition,
                'message': fields['message'],
                'transaction_id': this.state.deal_id,
            });
            fetch(process.env.REACT_APP_API_ROOT + 'transaction/listing_contact_broker', {
                method: 'POST',
                body: jsonBrokerContactFormData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
            }).then(function (res) {
                if (res.status < 200 || res.status >= 300) {
                    throw res;
                } else {
                    return res.json();
                }
            })
                .then(
                    (res) => {
                        document.getElementById('ContactBroker').setAttribute('style', 'display: none;');
                        let success = {success: ''};

                        if (res === true) {
                            success['success'] = 'Mail sent successfully.';
                        } else {
                            throw res.error;
                        }
                        this.resetcaptcha();
                        // eslint-disable-next-line
                        fields['fullName'] = '', fields['email'] = '', fields['phonenumber'] = '', fields['subject'] = '', fields['message'] = '';
                        this.setState({success: success, captchavalue: ''});
                        window.setTimeout(() => {
                            this.setState({success: ''});
                        }, 5000);
                    },
                )
                .catch((error) => {
                        document.getElementById('ContactBroker').setAttribute('style', 'display: none;');
                        this.setState({
                            gerror: error,
                        });
                    },
                );
            if (process.env.REACT_APP_ROOT === 'https://app.biznexus.com/') {
                let jsonHookBrokerContactFormData = JSON.stringify({
                    'name': fields['fullName'],
                    'email': fields['email'],
                    'subject': fields['subject'],
                    'message': fields['message'],
                    'phone': phonenumber,
                    'email_consent': this.state.newsletter,
                    'financing_consent ': this.state.acquisition,
                    'agree_to_terms ': this.state.agree,
                    'deal_id': this.props.match.params.dealDetails,
                    'deal_title': this.state.brokername,
                    'deal_url': process.env.REACT_APP_ROOT + 'deal-details/' + this.props.match.params.dealDetails,
                });
                fetch('https://hooks.zapier.com/hooks/catch/1629295/qw1v06/', {
                    method: 'POST',
                    body: jsonHookBrokerContactFormData,
                    headers: {
                        'Accept': 'application/json',
                    },
                }).then(function (res) {
                    if (res.status < 200 || res.status >= 300) {
                        throw res;
                    } else {
                        return res.json()
                    }
                })
                    .then(
                        (res) => {
                            //console.log(res);
                        },
                        (error) => {
                            this.setState({
                                //error
                            });
                        },
                    )
            }
        } else {
            // scrollToComponent(this.BrokerForm, {offset: -75, align: 'top', duration: 800})
            //console.log("Form has errors.")
        }
    }

    createMarkup(data) {
        data = data.replace(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g, '*********');
        data = data.replace(/(?:(?:\+?([1-9]|[0-9][0-9]|[0-9][0-9][0-9])\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([0-9][1-9]|[0-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/g, '*********');
        return {__html: data};
    }

    handleChangePrint = (e) => {
        e.preventDefault();
        window.print();
    }

    static contextType = AuthContext

    componentDidMount() {
        if (!localStorage.getItem('token')) {
            window.location.href = `/login?redirect_url=${window.location.href}`;
        }

        if (localStorage.getItem('token')) {
            this.setState({loggedIn: true});
        }

        //@ts-ignore
        const params = (new URL(document.location)).searchParams

        if (params.has('text')) {
            this.setState({chatMessage: params.get('text')});
        }

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_ROOT + 'user/header', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then(res => {
                if (res.status === 403) {
                    window.location.href = '/user/subscription';
                }
                return res.json()
            }).then(res => {
                if (res.status != 1) {
                    if (res.user_type_id === USER_TYPE.BROKER) {
                        window.location.href = '/setup-for-intermediaries';
                    } else {
                        window.location.href = '/setup';
                    }
                } else {
                    if (Boolean(res.admin_id)) {
                        this.setState({buyerName: res.name, slugUrl: ''})
                        this.getData()
                    } else if (res.listingsIds.includes(Number(this.state.deal_id))) {
                        this.getData()
                    } else if (res.user_type_id === USER_TYPE.BROKER) {
                        window.location.href = '/home';
                    } else if (!res.premium_level || res.premium_level < SubscriptionLevels.PREMIUM) {
                        this.setState({needUpgrade: true})
                    } else {
                        this.setState({buyerName: res.name, slugUrl: ''})
                        this.getData()
                    }
                    this.setState(prevState => ({
                        ...prevState,
                        buyerName: res.name,
                        isUserAdmin: Boolean(res.admin_id)
                    }))
                }
            })
        }

    }

    downloadDeal = async () => {
        return fetch(process.env.REACT_APP_API_ROOT + 'transaction/report', {
            method: 'post',
            body: JSON.stringify({deal_id: this.state.deal_id }),
            headers: {
                Accept: 'text/csv',
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
                'x-access-token': localStorage.getItem('token')
            },
        })
            .then(function (res) {
                if (res.status < 200 || res.status >= 300) {
                    throw res;
                } else {
                    return res
                }
            })
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = `deal-#${this.state.deal_id}-${moment().format("DD-MM-YYYY")}.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.log(err)
            })
    }

    getData = async () => {
        fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + this.state.deal_id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'content-type': 'application/json',
                'x-access-token': localStorage.getItem('token'),
            },
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(res.statusText)
                }
                return res.json()
                }
            )
            .then(res => {
                if (res.id) {
                let metaTitle = '';
                let metaDescription = '';
                let metaLocation = '';
                metaLocation = window.location.href;

                // IGOR: this will need to change to take location visibility into account
                if (res.location_address !== '' && res.location_address !== null) {
                    metaTitle = res.name + ' - Business For Sale in ' + res.location_address + ' - BizNexus';
                } else {
                    metaTitle = res.name + ' - Business For Sale - BizNexus';
                }

                if (res.description !== null && res.description !== '') {
                    var rex = /(<([^>]+)>)/ig;
                    metaDescription = res.description.replace(rex, '');
                } else {
                    metaDescription = '';
                }

                    if (res.video && res.video.indexOf('youtube') !== -1) {
                    let video_src = res.video.split('?v=')[1];
                    var ampersandPosition = video_src.indexOf('&');
                    if (ampersandPosition !== -1) {
                        video_src = video_src.substring(0, ampersandPosition);
                    }

                    if (video_src) {
                        res.video = `
                    <iframe frameborder="0" src="https://www.youtube.com/embed/${video_src}" width="440" height="300"></iframe>
                  `;
                    }
                }

                let cityValue = res.area_served;
                let slugValue = '';
                let getLocationData = cityValue && cityValue.length >= 1 ? JSON.parse(cityValue)[0] : undefined;

                if (getLocationData !== undefined) {
                    if (getLocationData.city !== "" && getLocationData.city !== undefined) {

                        let locationData = getLocationData.location;
                        let city = '';
                        locationData = locationData.replace(/[^a-zA-Z0-9 ]/g, "");
                        locationData = locationData.trim();
                        locationData = locationData.replace(/ /g, "-");
                        locationData = locationData.replace(/--/, "-");
                        locationData = locationData.toLowerCase();
                        city = locationData;
                        let slugId = res.guru_id;
                        slugValue = city + "/" + res.guru_name.toLowerCase() + "-" + slugId;

                    } else {

                        let slugId = res.guru_id;
                        slugValue = res.guru_name.toLowerCase() + "-" + slugId;

                    }
                }

                let historical = res.historical;
                let ttm = {};
                if (historical) {
                    historical = JSON.parse(historical);
                    ttm = historical.filter((d) => d.year === 'ttm')[0];
                    historical = historical.filter((d) => d.year !== 'ttm').sort((a, b) => {
                        return a.year - b.year;
                    });

                    if (historical.length === 1) {
                        if (!historical[0].cashflow && !historical[0].ebitda && !historical[0].revenue) {
                            historical = [];
                        }
                    }
                } else {
                    historical = [];
                }

                let faq = res.faq;

                if (faq) {
                    faq = JSON.parse(faq);
                } else {
                    faq = [];
                }
                const {industries} = res;
                const first_industry = industries.find(e => e.industry.parent_id === 1);
                const first_sub_industry = industries.find(e => e.industry.parent_id !== 1);

                let listingimage = res.image

                if (!listingimage) {
                    if (res.keywords && res.keywords.indexOf('FSBO') >= 0) {
                        listingimage = process.env.REACT_APP_ROOT + 'images/listing-page/fsbo_default.png'
                    }
                    if (first_industry) {
                        listingimage = defaultListingImages[first_industry.industry.id]
                    } else if (industries[0] && industries[0].industry.parent_id) {
                        listingimage = defaultListingImages[industries[0].industry.parent_id]
                    }

                    if (first_sub_industry) {
                        listingimage = defaultListingImages[first_sub_industry.industry.id] || listingimage
                    }

                }
                const data = {
                    ...res,
                    profile_pic: res && res.guru && res.guru.profile_pic || '',
                    deal_id: res.id,
                    fields: [],
                    errors: {},
                    success: {},
                    brokername: res.name,
                    firstname: res.guru_name,
                    guruEmail: res.guru && res.guru.email_visible ? res.guru.email : '',
                    onlyfirstname: res.keywords === 'FSBO' ? 'Seller' : res.guru_name,
                    isFSBO: res.keywords === 'FSBO',
                    listingimage,
                    brokerdescription: res.description,
                    brokerprice: res.price,
                    brokerdate: res.date,
                    brokerrevenue: res.revenue,
                    brokercashflow: res.cashflow,
                    brokerlocation_address: res.location_address,
                    brokercompany_name: res.company_name,
                    brokerprofit: res.profit,
                    brokerequipment: res.equipment,
                    brokerebitda: res.ebitda,
                    brokerppe: res.ppe,
                    brokerinventory: res.inventory,
                    brokerreason_for_selling: res.reason_for_selling,
                    brokerfacilities: res.facilities,
                    brokerfinancing: res.financing,
                    brokercompetition: res.competition,
                    brokergrowth_opportunity: res.growth_opportunity,
                    brokerongoing_support: res.ongoing_support,
                    metaTitle: metaTitle,
                    metaDescription: metaDescription,
                    metaLocation: metaLocation,
                    showMessagesBlock: null,
                    chatMessage: '',
                    modal: false,
                    video: res.video,
                    industry_id: res.industries ? res.industries[0].industry_id : null,
                    latitude: res.latitude,
                    longitude: res.longitude,
                    similarList: [],
                    slugName: slugValue,
                    real_estate: res.real_estate,
                    revenue_multiple: res.revenue_multiple,
                    ebitda_multiple: res.ebitda_multiple,
                    cashflow_multiple: res.cashflow_multiple,
                    savedListing: false,
                    modified_at: res.modified_at,
                    created_at: res.created_at,
                    seller_financing: res.seller_financing,
                    leased_or_owned: res.leased_or_owned,
                    included_in_asking_price: res.included_in_asking_price,
                    listingNote: [],
                    listingNoteText: '',
                    favoriteCount: res.favoriteCount,
                    priceChange: res.priceChange,
                    historical: historical,
                    ttm,
                    faq,
                    premium: false,
                    premium_obj: null,
                    competitive_advantages: res.competitive_advantages,
                    teaser: res.teaser,
                    nda: res.nda,
                    hide_price: res.hide_price,
                    stats: res.stats,
                    responseTime: '',
                    free: false,
                    contactSellerButton: true,
                    buyerName: '',
                    assignListingModal: false,
                    assignListingTo: '',
                    savedSearchProjects: [],
                    completed: res.completed,
                    brokerName: res.brokerName,
                    brokerPhone: res.brokerPhone,
                    brokerOfficePhone: res.brokerOfficePhone,
                    broker_company_name: res.broker_company_name,
                    broker_site_url: res.broker_site_url,
                    brokerEmail: res.brokerEmail,
                    broker_external_url: res.broker_external_url,
                    integration_id: res.integration_id,
                    name: '',
                    keywords: res.keywords,
                    target_markets: res.target_markets,

                    cashflow: '',
                    ebitda: '',
                    revenue: '',
                    price: '',

                    want_to_sell: res.want_to_sell,
                    listing_date: res.listing_date,

                    abuseModal: false,
                    abuseText: '',
                    partnerModal: false,
                    partnerModalErrorMessage: false,
                }
                this.setState({...data, dataIsLoaded: true})

                fetch(process.env.REACT_APP_API_ROOT + 'messages/get_response_time/' + res.guru_id, {
                    method: 'get',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }).then(function (res) {
                    if (res.status < 200 || res.status >= 300) {
                        throw res;
                    } else {
                        return res.json()
                    }
                })
                    .then(response => {
                        let responseTime = '';

                        if (response.time <= 1) {
                            responseTime = `Typically replies within 1 hour`
                        } else if (response.time > 1 && response.time <= 5) {
                            responseTime = `Typically replies within ${response.time} hours`
                        } else if (response.time > 5 && response.time <= 24) {
                            responseTime = `Typically replies same day`
                        } else if (response.time > 24 && response.time <= 168) {
                            responseTime = `Typically replies within 1 week`
                        } else {
                            responseTime = `Typically takes longer than a week to reply`
                        }
                        this.setState({responseTime, responseTimeRaw: response.time });
                    });

                fetch(process.env.REACT_APP_API_ROOT + 'messages/free', {
                    method: 'get',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('token'),
                    },
                }).then(function (res) {
                    if (res.status < 200 || res.status >= 300) {
                        throw res;
                    } else {
                        return res.json()
                    }
                })
                    .then(response => {
                        this.setState({free: response.free});
                    });

                if (data.brokerprice) {
                    const price = parseInt(data.brokerprice.replace(/\D+/g, ''));
                    const price_min = price - (price * 0.25);
                    const price_max = price + (price * 0.25);
                    fetch(process.env.REACT_APP_API_ROOT + 'transaction/search', {
                        method: 'post',
                        body: JSON.stringify({
                            price_min,
                            price_max,
                            industry_id: data.industry_id,
                            from_latitude: data.latitude,
                            from_longitude: data.longitude
                        }),
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            "Access-Control-Allow-Origin": "*",
                        },
                    }).then(r => r.json())
                        .then(r => {
                            this.setState({
                                similarList: r.list.filter((l) => l.id !== data.deal_id)
                            });
                            const viewport = document.querySelectorAll('.drag-viewport');
                            const content = document.querySelectorAll('.drag-content');
                            if (viewport[0] && content[0]) {
                                for (let i = 0; i < viewport.length; i++) {
                                    new ScrollBooster({
                                        // @ts-ignore
                                        viewport: viewport[i],
                                        // @ts-ignore
                                        content: content[i],
                                        direction: "horizontal",
                                        scrollMode: "native",
                                        emulateScroll: true,
                                        onUpdate: (state) => {
                                            //@ts-ignore
                                            viewport.scrollLeft = state.position.x;
                                        }
                                    });
                                }
                            }
                        });
                }

                fetch(process.env.REACT_APP_API_ROOT + 'messages/get_by_thread/' + res.guru_id, {
                    method: 'get',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('token'),
                    },
                }).then(function (res) {
                    if (res.status < 200 || res.status >= 300) {
                        throw res;
                    } else {
                        return res.json()
                    }
                }).then(response => {
                    if (response.filter((r) => r.transaction_id === this.state.deal_id).length) {
                        this.setState({showMessagesBlock: true});
                    } else {
                        this.setState({showMessagesBlock: false});
                    }
                }).catch(() => {
                    this.setState({showMessagesBlock: false});
                });

                fetch(process.env.REACT_APP_API_ROOT + 'transaction/track/' + data.deal_id, {
                    method: 'get',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'x-access-token': localStorage.getItem('token'),
                    },
                });

                if (localStorage.getItem('token')) {
                    this.getSaveListing();
                    this.getListingNotes();
                    this.getPremium(data.deal_id, data.guru_id);

                    ReactGA.event('viewed_page', {
                        category: 'User',
                        action: 'viewed_page',
                        label: 'User ID',
                        value: this.context.user.id
                    });
                } else {
                    const name = renameListing({
                        name: data.brokername,
                        industries: data.industries,
                        price: data.brokerprice,
                        cashflow: data.brokercashflow,
                        revenue: data.brokerrevenue,
                        ebitda: data.brokerebitda
                    }, false);
                    this.setState({
                        name,
                        cashflow: roundedMertic(data.brokercashflow, true),
                        revenue: roundedMertic(data.brokerrevenue, true),
                        ebitda: roundedMertic(data.brokerebitda, true),
                        price: roundedMertic(data.brokerprice, true),
                    });
                }
            }
        })
            .catch(err => {
                console.log(err);
                //@ts-ignore
                window.location = 'https://biznexus.com/404';
            })
    }

    getPremium = async (deal_id, guru_id) => {
        if(localStorage.getItem('token')) {
            const header = await fetch(process.env.REACT_APP_API_ROOT + 'transaction/' + deal_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'application/json',
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then(res => {
                if (res.status === 403) {
                    window.location.href = '/user/subscription';
                }
                return res.json()
            })
                .then(res => {

                    if (res.user_type_id === 3 && guru_id === 0) {
                        //@ts-ignore
                        window.location = 'https://biznexus.com/expired_listing';
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        premium: res.premium,
                        premium_obj: res.premium_obj,
                        name: prevState.brokername || '',
                        cashflow: prevState.brokercashflow || 'Not Specified',
                        revenue: prevState.brokerrevenue || 'Not Specified',
                        ebitda: prevState.brokerebitda || 'Not Specified',
                        price: prevState.brokerprice || 'Not Specified',
                    }))
                })
        }
    }

    getListingNotes = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'listing_notes/get/' + this.state.deal_id, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json())
            .then(res => {
                this.setState({listingNote: res, listingNoteText: ''});
            });

    }

    updateNote = () => {
        if (this.state.listingNoteText) {
            fetch(process.env.REACT_APP_API_ROOT + 'listing_notes/save', {
                body: JSON.stringify({
                    //@ts-ignore
                    id: this.state.deal_id,
                    text: this.state.listingNoteText,
                }),
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then(() => {
                this.getListingNotes();
            })
        }

    }

    deleteNote = id => {
        fetch(process.env.REACT_APP_API_ROOT + 'listing_notes/delete', {
            body: JSON.stringify({
                //@ts-ignore
                id
            }),
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.getListingNotes();
        })
    }

    truncString(str, max, add) {
        add = add || '...';
        return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
    };

    getSaveListing = () => {
        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_listings/' + this.state.deal_id, {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json())
            .then(res => {
                if (res.id) {
                    this.setState({savedListing: true});
                } else {
                    this.setState({savedListing: false});
                }
            });

        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_search', {
            method: 'get',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(res => res.json())
            .then(res => {
                this.setState({
                    savedSearchProjects: res.savedSearchProjects,
                });
            });
    }

    changeSaveListing = () => {
        if (this.state.savedListing) {
            fetch(process.env.REACT_APP_API_ROOT + 'user/saved_listings/' + this.state.deal_id, {
                method: 'delete',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then(res => this.getSaveListing());
        } else {
            this.setState({assignListingModal: true});
        }

    }

    handleSendMessage = () => {

        if (!localStorage.getItem('token')) {
            this.toggle();
            return;
        }

        if (!this.state.chatMessage) {
            return;
        }

        this.setState({showMessagesBlock: !this.state.showMessagesBlock});
    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    premiumToggle = () => {
        this.setState({
            premiumModal: !this.state.premiumModal
        });
    }

    redirectLink(name, state) {
        const currentUrl = window.location.href + '?text=' + state.chatMessage;
        return `/${name}?redirect_url=${currentUrl}`;
    }

    isLoggedIn() {
        return !!localStorage.getItem('token');
    }

    goToLoginPage(e) {
        e.preventDefault();
        window.location.href = `/login?redirect_url=${window.location.href}`;
    }

    currentURL = () => {
        return window.location;
    }

    onClickAssignListing = () => {
        if (!this.state.assignListingTo) {
            return null;
        }

        this.setState({assignListingModal: false});
        fetch(process.env.REACT_APP_API_ROOT + 'user/saved_listings/' + this.state.deal_id, {
            method: 'post',
            body: JSON.stringify({
                project_id: this.state.assignListingTo.value,
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => this.getSaveListing());
    }

    onContactSeller = () => {

        if (!localStorage.getItem('token')) {
            this.toggle();
            return;
        }

        window.scrollTo(0, 0);

        if (this.state.guru_id === 0 && !this.state.brokerEmail && !this.state.brokerPhone && !this.state.brokerOfficePhone && !this.state.broker_site_url && !this.state.broker_company_name) {
            window.open(this.state.broker_external_url, '_blank');
        } else {
            this.setState({
                contactSellerButton: false,
                showMessagesBlock: false,
                chatMessage: `I'm interested in this deal. Please let me know more and what the next steps are. -${this.state.buyerName}`
            })
        }

    }

    onAbuseSubmit = () => {
        if (this.state.abuseText.length === 0) {
            return;
        }

        fetch(process.env.REACT_APP_API_ROOT + 'transaction/abuse_submit/', {
            method: 'post',
            body: JSON.stringify({
                listing_name: this.state.name,
                reporter: this.state.buyerName,
                listing: window.location.href,
                text: this.state.abuseText,
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('token'),
            },
        }).then(() => {
            this.setState({abuseModal: false});
        });
    }

    partnerClick = () => {
        // if( ! localStorage.getItem('token')) {
        //   this.setState({ modal: true });
        // }

        const name = this.state.partnerServiceName;
        const {partnerName, partnerEmail, partnerLastName} = this.state;

        if (localStorage.getItem('token')) {
            fetch(process.env.REACT_APP_API_ROOT + 'transaction/partner_request', {
                method: 'post',
                body: JSON.stringify({
                    url: window.location.href,
                    partnerServiceName: name,
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then(() => {
                this.setState({partnerModal: false});
            });
        } else {
            if (partnerName && partnerEmail && partnerLastName) {
                fetch(process.env.REACT_APP_API_ROOT + 'transaction/partner_request_anonymous', {
                    method: 'post',
                    body: JSON.stringify({
                        url: window.location.href,
                        partnerServiceName: name, partnerName, partnerEmail, partnerLastName
                    }),
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                }).then(() => {
                    this.setState({partnerModal: false});
                });
            } else {
                this.setState({partnerModalErrorMessage: true});
            }
        }


    }

    render() {
        if (!this.state || !this.state.dataIsLoaded && !this.state.needUpgrade) {
            return (null);
        }
        if (this.state.needUpgrade) {
            return <UpgradeSubscriptionModal slugUrl={this.state.slugUrl}/>
        }
        let state = this.state
        const pic_url = this.state && this.state && this.state.profile_pic || ''
        const profile_pic = (pic_url && pic_url !== '') ? getImageUrl(pic_url) : `/images/logo-big.png`



        const {listingimage} = state;
        const meta = {
            'og:title': state.metaTitle,
            'og:description': state.metaDescription,
            'description': state.metaDescription,
            'og:image': listingimage ? getImageUrl(listingimage) : process.env.PUBLIC_URL + '/images/thumbnail-full.jpg',
            meta: {
                charset: 'utf-8',
            },
        };

        const showHistorical = () => {
            return state.loggedIn && (state.historical.length > 0 || (state.ttm.cashflow || state.ttm.ebitda || state.ttm.revenue));
        };

        const priceChangeLabel = (p, i) => {
            const formula = parseInt(((parseInt(p.price.replace(/,/g, ''))) / parseInt(state.priceChange[i - 1].price.replace(/,/g, '')) * 100 - 100).toFixed(0));
            return isNaN(formula) ? `n/a` : `${formula}%`;
        }

        const renderNewTabIcon = () => {
            if (state.guru_id === 0 && !state.brokerEmail && !state.brokerPhone && !state.brokerOfficePhone && !state.broker_site_url && !state.broker_company_name) {
                return <i className="ms-2 fa-solid fa-up-right-from-square"/>
            }
        }

        const renderBrokerContact = (rightButton = false) => {
            const firmName = {
                23: 'WebsiteProperties',
                15: 'Bizex',
                26: 'RoiCorp',
                12: 'Vestedbb',
                6: 'FCBB',
                21: 'Vikingmergers',
                27: 'Trufortebusinessgroup',
                24: 'ecommerceBrokers',
                18: 'Benchmark',
                10: 'Cbiteam',
                4: 'Transworld',
                19: 'Tbbinc',
                25: 'synergyBB',
                13: 'Saintlouisgroup',
                5: 'Sunbelt',
                // 22: 'Amma',
                16: 'Inbargroup',
                17: 'Horizonbrokers',
                20: 'Capitalbbw',
                28: 'Wcibusinessbrokers',
                14: 'BusinessTeam',
                8: 'Link Business',
                29: 'Fbxbrokers',
                30: 'Bay State Business Brokers',
                31: 'Premierbb',
                32: 'CertifiedBusinessBroker',
                33: 'BusinessFindersCanada',
                34: 'BizConsultingExpert',
                35: 'TmBusinessBrokers',
                36: 'SBX',
                37: 'RainCatcher',
                38: 'CompanySellers',
                39: 'TheBusinessExchange',
                40: 'AppBusinessBrokers',
                41: 'VRBusinessBrokers',
                42: 'CalhounCompanies',
                43: 'MoneyNomad',
                44: 'EcomBrokers',
                45: 'SellerForce',
                46: 'BusinessExits',
                47: 'ExitEquity',
                48: 'Colliers',
                50: 'WestboundRoad',
                51: 'BizExBusinessBrokers',
                52: 'InternationalBusinessExchange',
                53: 'Murphy',
                54: 'BristolGroup',
                55: 'BizMLS',
                56: 'TheVeldGroup',
                57: 'StoneridgePartners',
                58: 'AmericanHealthcare',
                59: 'GottesmanCompany',
                60: 'EmbraceBenchmark',
                61: 'DealForce',
                62: 'Intelligent',
                63: 'MergersCorp',
                64: 'GoldStarBusinessBrokers',
                65: 'ArizonaRestaurantSales',
                66: 'StrategicMedicalBrokers',
                67: 'TNTBusinessBrokers',
                68: 'BusinessBrokersIntermediaries',
                69: 'AegisBusinessAdvisors',
                70: 'SouthwestBusinessBrokerageLlc',
                71: 'CantrellGriffinBusinessBrokers',
                72: 'AllenYoungBusinessBrokerage',
                73: 'MergerAcquisitionSpecialists',
                74: 'SoCalRestaurantBroker',
                75: 'OldIncBusinessBrokers',
                76: 'WebsiteClosers',
                77: 'AcquisitionsDirect',
                78: 'RestaurantRealtyCompany',
                79: 'ArrayCapital',
                80: 'FEInternational',
                81: 'InvestorsClub',
                82: 'Businessesforsale',
                83: 'MidStreetMergersNAcquisitions',
                84: 'SunbeltCanada',
                85: 'TheLibertyGroup',
                86: 'MyBizsale',
                87: 'TinsleyMedicalPracticeBrokers',
                88: 'Woodbridge',
                89: 'FCBBAU',
                90: 'TheDealFirm',
                91: 'GulfStreamMergers',
                92: 'Veldma',
                93: 'CalderMiddleMarket',
                94: 'CalderMainStreet',
                95: 'PoeGroupAdvisors',
                96: 'HedgeStone',
                97: 'Vallexa',
                98: 'TheFirm',
                100: 'TouchstoneBusinessAdvisors',
                101: 'ApexBusinessAdvisors',
                102: 'AdvancedBusinessBrokers',
                103: 'KensingtonCompanyAffiliates',
                104: 'AlpineBusinessBrokers',
                105: 'AmericanBusinessBrokers',
                106: 'RegalConsultingCorp',
                107: 'EdisonBusinessAdvisors',
                108: 'AffiliatedBusinessBrokers',
                109: 'TheCBAGroup',
                110: 'GatewayBusinessAdvisors',
                111: 'ArmenNazarian',
                112: 'CoastalBusinessBrokers',
                113: 'SantaRosaBusinessCommercial',
                114: 'EvolutionAdvisors',
                115: 'FrontRangeBusiness',
                116: 'SourceWorksInc',
                117: 'CincinnatiBusinessBrokers',
                118: 'ProFormaPartnersLLC',
                119: 'TheSalehGroup',
                120: 'OcalaBusinessBrokersInc',
                121: 'PoolRouteSales',
                122: 'RFS',
                123: 'HughesProperties',
                124: 'ABAAdvisors',
                125: 'CorporateInvestmentBusinessBrokerage',
                126: 'AllenYoung',
                127: 'PSBroker',
                128: 'RiverBrokers',
                129: 'TheRestaurantBrokers',
                130: 'BizMatch',
                131: 'MorganWestfield',
                132: 'EnlignAdvisors',
                133: 'MurrayAssociates',
                134: 'ListitenAssociates',
                135: 'BottomLineManagement',
                136: 'InbarGroupInc',
                137: 'BusinessBrokersOfArizona',
                138: 'CorbettRestaurantGroup',
                139: 'BMIMergers',
                140: 'FusionAdvantage',
                141: 'NashvilleBusinessBrokers',
                142: 'AMDBusinessBrokers',
                143: 'PacificCommercialBrokers',
                144: 'SDBusinessAdvisors',


                500: 'MailParser',
                99: 'ManuallyAdded',
            };


            if (state.guru_id === 0 && state.showMessagesBlock === false && state.contactSellerButton === false) {
                const companyName = firmName[state.integration_id] === 'BizMLS' || firmName[state.integration_id] === 'ManuallyAdded' ? state.broker_company_name : firmName[state.integration_id] === 'MailParser' ? state.agent_source : firmName[state.integration_id]
                return (
                    <div className="mb-4" style={{textAlign: 'center', padding: '10px', border: '3px solid #3d93db'}}>
                        <div><b>Representing Firm: </b>{companyName || '-'}  </div>
                        <div><b>Name: </b>{state.brokerName || '-'}  </div>
                        <div><b>Email: </b>{state.brokerEmail ?
                            <a href={`mailto:${state.brokerEmail}`}>{state.brokerEmail}</a> : '-'}  </div>
                        <div><b>Phone: </b>{state.brokerPhone || '-'}  </div>
                        <div><b>Office Phone: </b>{state.brokerOfficePhone || '-'}  </div>
                        {state.broker_site_url ?
                            <div><b>Broker Site: </b><a href={state.broker_site_url} target="_blank">Link</a></div> : ''}
                        {(state.isUserAdmin || state.isConcierge) && state.broker_external_url ?
                            <div><b>Source Link: </b><a href={state.broker_external_url} target="_blank">Link</a></div> : ''}
                    </div>
                );

            } else if (state.showMessagesBlock) {
                return (
                    <div className="d-xl-block mb-4">
                        <NewChat
                            message={state.chatMessage}
                            guru_avatar={profile_pic}
                            guru_name={state.onlyfirstname}
                            isFSBO={state.isFSBO}
                            showOtherOptions={state.responseTimeRaw > 24}
                            guru_id={state.guru_id}
                            guruEmail={state.guruEmail}
                            body={`I'm interested in learning more about your deal "${state.brokername}" Can you please provide me with more information or the NDA so can review? `}
                            transaction_id={state.deal_id}
                            nda={state.nda}
                        />
                    </div>
                );
            } else {
                return (
                    (state.showMessagesBlock === false && state.contactSellerButton === false) &&
                    <div>
                        <label htmlFor="contact-seller" className="sr-only"/>
                        <textarea value={state.chatMessage} onChange={(e) => this.setState({chatMessage: e.target.value})}
                                  className="form-control border-primary-blue mb-2" name="contact-seller" id="contact-seller"
                                  rows={6}
                                  style={{height: 'auto'}}
                                  placeholder={`I'm interested in this deal. Please let me know more and what the next steps are.`}/>
                        <button onClick={this.handleSendMessage} className="btn btn-primary-blue w-100 mb-4">Send a Message
                        </button>
                    </div>
                );
            }
        };

        const chartOptions = {
            animation: {
                duration: 1,
                onComplete: function ({chart}) {
                    const chartInstance = chart;
                    const ctx = chartInstance.ctx;

                    ctx.textAlign = "center";
                    ctx.textBaseline = "bottom";
                    ctx.fillStyle = "#829eb9";

                    this.data.datasets.forEach(function (dataset, i) {
                        var meta = chartInstance.getDatasetMeta(i);
                        meta.data.forEach(function (bar, index) {
                            const data = (+dataset.data[index]).toLocaleString("en-US", {style: "currency", currency: "USD"});
                            let padding = 0;
                            const egdeElementPadding = 20
                            if (index === 0) {
                                padding += egdeElementPadding;
                            } else if (index === meta.data.length - 1) {
                                padding -= egdeElementPadding;
                            }
                            ctx.fillText(data, bar.x + padding, bar.y - 5);
                        });
                    });
                },
            },
            responsive: true,
            maintainAspectRatio: false,

            legend: {
                display: false,
            },
            elements: {
                line: {
                    fill: '#ccc'
                }
            },
            layout: {
                padding: {
                    top: 20,
                    left: 25,
                    right: 25
                },
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.yLabel.toLocaleString("en-US", {style: "currency", currency: "USD"});
                    }
                }
            },
            backgroundColor: "#FAFAFA",
            color: '#4482ce',
            plugins: {
                legend: {
                    display: false,
                },
            },
            scales: {
                y: {
                    display: false,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    grid: {
                        display: false, // Hide x-axis grid lines
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                },
                x: {
                    grid: {
                        display: false, // Hide x-axis grid lines
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false,
                        color: "#B5CEE3",
                    },
                },
            },
        };

        const historicalRevenueChart = () => {
            const points = state.historical.filter(h => h.revenue && h.revenue !== "0");

            if (state.ttm.revenue) {
                points.push({year: 'TTM', revenue: state.ttm.revenue});
            }

            return points.length >= 2 ? points.map(h => ({...h, revenue: h.revenue.replace(/[.,]/g, '')})) : [];
        };

        const historicalCashflowChart = () => {
            const points = state.historical.filter(h => h.cashflow && h.cashflow !== "0");

            if (state.ttm.cashflow) {
                points.push({year: 'TTM', cashflow: state.ttm.cashflow});
            }

            return points.length >= 2 ? points.map(h => ({...h, cashflow: h.cashflow.replace(/[.,]/g, '')})) : [];
        };

        const historicalEBITDAChart = () => {
            const points = state.historical.filter(h => h.ebitda && h.ebitda !== "0");

            if (state.ttm.ebitda) {
                points.push({year: 'TTM', ebitda: state.ttm.ebitda});
            }

            return points.length >= 2 ? points.map(h => ({...h, ebitda: h.ebitda.replace(/[.,]/g, '')})) : [];
        };

        const onCreateNewProject = () => {
            if (!this.state.projectName) {
                return null;
            }
            this.setState({newProjectModal: false});
            fetch(process.env.REACT_APP_API_ROOT + 'user/create_new_project', {
                method: 'post',
                body: JSON.stringify({
                    name: this.state.projectName,
                }),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-token': localStorage.getItem('token'),
                },
            }).then(() => {
                this.setState({projectName: ''});
                this.getSaveListing();
            });
        }

        return (
            <div className="">
                <Header slugUrl={state.slugName}/>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{state.metaTitle}</title>
                    <meta name="description" content={state.metaDescription}/>
                    <meta property="og:title" itemProp="title name" content={state.metaTitle}/>
                    <meta property="og:description" itemProp="description" content={state.metaDescription}/>
                    <meta property="og:site_name" itemProp="site_name" content="BizNexus Marketplace"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content={state.metaLocation}/>
                    <meta property="og:image" itemProp="image primaryImageOfPage" content={meta['og:image']}/>
                </Helmet>


                <div className="listing-page" style={{paddingTop: 30}}>
                    <div className="container">


                        <section className="row align-items-center mb-3">
                            <div className="col-auto ms-sm-auto">
                                <ul className="share-list list-unstyled">
                                    <li className="text-secondary text-sm">
                                        Share
                                    </li>
                                    <li>
                                        <EmailShareButton subject={`${state.brokername}`}
                                                          body={`I'm interested in learning more about your deal "${state.brokername}" Can you please provide me with more information or the NDA so can review? `}
                                                          url={this.currentURL()}>
                                            <button className="btn btn-unstyled share-list-icon" aria-label="Print">
                                                <i className="fas fa-at"/>
                                            </button>
                                        </EmailShareButton>
                                    </li>

                                    <li>
                                        <LinkedinShareButton url={this.currentURL()}>
                                            <button className="btn btn-unstyled share-list-icon" aria-label="Print">
                                                <svg className="icon" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M17.5 35C27.165 35 35 27.165 35 17.5C35 7.83502 27.165 0 17.5 0C7.83502 0 0 7.83502 0 17.5C0 27.165 7.83502 35 17.5 35ZM10.2643 24.4712H13.5814V13.4749H10.2643V24.4712ZM10 9.97855C10 11.0705 10.8604 11.9749 11.9211 11.9749C12.9814 11.9749 13.8421 11.0705 13.8421 9.97855C13.8421 9.45407 13.6397 8.95106 13.2795 8.58019C12.9192 8.20933 12.4306 8.00098 11.9211 8.00098C11.4116 8.00098 10.9229 8.20933 10.5627 8.58019C10.2024 8.95106 10 9.45407 10 9.97855ZM22.6864 24.4712H25.9964H26V18.4308C26 15.4749 25.3829 13.1991 22.0257 13.1991C20.4118 13.1991 19.33 14.1109 18.8871 14.9749H18.8407V13.4749H15.6593V24.4712H18.9729V19.0263C18.9729 17.5925 19.2371 16.2065 20.9618 16.2065C22.6614 16.2065 22.6864 17.8425 22.6864 19.1183V24.4712Z"
                                                          fill="currentColor"/>
                                                </svg>


                                            </button>
                                        </LinkedinShareButton>
                                    </li>

                                    <li>
                                        <FacebookShareButton url={this.currentURL()}><a href="#" className="share-list-icon">
                                            <svg className="icon" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M17.5 0C7.83504 0 0 7.83504 0 17.5C0 27.165 7.83504 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83504 27.165 0 17.5 0ZM19.3241 18.2687V27.7896H15.3848V18.2691H13.4167V14.9881H15.3848V13.0183C15.3848 10.3416 16.496 8.75 19.6534 8.75H22.2819V12.0313H20.6389C19.4098 12.0313 19.3285 12.4898 19.3285 13.3456L19.3241 14.9877H22.3005L21.9522 18.2687H19.3241Z"
                                                      fill="currentColor"/>
                                            </svg>

                                        </a></FacebookShareButton>
                                    </li>
                                    <li>
                                        <TwitterShareButton url={this.currentURL()}>
                                            <a href="#" className="share-list-icon">
                                                <svg className="icon" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M17.5 0C7.83504 0 0 7.83504 0 17.5C0 27.165 7.83504 35 17.5 35C27.165 35 35 27.165 35 17.5C35 7.83504 27.165 0 17.5 0ZM17.0126 14.8298L16.9759 14.2242C16.8657 12.6548 17.8327 11.2213 19.3629 10.6651C19.9259 10.4674 20.8807 10.4427 21.505 10.6157C21.7498 10.6899 22.215 10.937 22.5455 11.1595L23.1453 11.5673L23.8063 11.3572C24.1736 11.2459 24.6632 11.0606 24.8835 10.937C25.0916 10.8258 25.2752 10.764 25.2752 10.8011C25.2752 11.0111 24.8223 11.7279 24.4428 12.1234C23.9287 12.6795 24.0756 12.7289 25.1161 12.3582C25.7404 12.1481 25.7526 12.1481 25.6302 12.3829C25.5567 12.5064 25.1772 12.939 24.7733 13.3345C24.0878 14.0141 24.0511 14.0883 24.0511 14.6568C24.0511 15.5342 23.6349 17.3631 23.2187 18.3641C22.4475 20.2425 20.795 22.1828 19.1425 23.1591C16.8168 24.5308 13.7198 24.8768 11.1125 24.0735C10.2434 23.8017 8.75 23.1096 8.75 22.986C8.75 22.9489 9.20289 22.8995 9.75377 22.8872C10.9044 22.8624 12.055 22.5412 13.0343 21.9726L13.6954 21.5772L12.9364 21.3177C11.8592 20.947 10.8921 20.0942 10.6474 19.291C10.5739 19.0315 10.5984 19.0191 11.2839 19.0191L11.9938 19.0067L11.394 18.7225C10.684 18.3641 10.0353 17.7586 9.71702 17.1407C9.48449 16.6958 9.19071 15.5713 9.27639 15.4847C9.30081 15.4477 9.55792 15.5218 9.8517 15.6207C10.6963 15.9297 10.8065 15.8555 10.3168 15.3364C9.39874 14.3972 9.11721 13.0008 9.55792 11.6785L9.76602 11.0853L10.5739 11.8885C12.2264 13.5074 14.1727 14.4714 16.4006 14.7556L17.0126 14.8298Z"
                                                          fill="currentColor"/>
                                                </svg>

                                            </a></TwitterShareButton>
                                    </li>

                                </ul>
                            </div>
                        </section>


                        <section className="mb-4" data-toggle="tooltip" data-placement="top">


                            <div className="listing-info-card" style={{position: 'relative'}}>

                                <div className="listing-info-card__content">

                                    <div className="d-flex align-items-center mb-3">

                                        <h1 className="listing-info-card__title mb-0 pr-4">{state.name}</h1>

                                        <div className="d-none d-sm-block ms-1">
                                            <div className="listing-info-card__actions">

                                                {state.loggedIn && <button style={{color: state.savedListing ? 'red' : '#3890da'}}
                                                                           onClick={this.changeSaveListing.bind(this)}
                                                                           className="btn btn-unstyled">
                                                    {state.savedListing ?
                                                        <svg style={{fontSize: 24}} className="icon" viewBox="0 0 24 23" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M12.0049 1.96984C18.6619 -4.87316 35.3059 7.10134 12.0049 22.4988C-11.2961 7.10284 5.34792 -4.87316 12.0049 1.96984Z"
                                                                  fill="currentColor"></path>
                                                        </svg> :
                                                        <svg className="icon" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M12.0023 4.12093L10.9268 3.01543C8.40228 0.420428 3.77328 1.31593 2.10228 4.57843C1.31778 6.11293 1.14078 8.32843 2.57328 11.1559C3.95328 13.8784 6.82428 17.1394 12.0023 20.6914C17.1803 17.1394 20.0498 13.8784 21.4313 11.1559C22.8638 8.32693 22.6883 6.11293 21.9023 4.57843C20.2313 1.31593 15.6023 0.418928 13.0778 3.01393L12.0023 4.12093ZM12.0023 22.4989C-10.9972 7.30093 4.92078 -4.56107 11.7383 1.71343C11.8283 1.79593 11.9168 1.88143 12.0023 1.96993C12.0869 1.88151 12.175 1.79644 12.2663 1.71493C19.0823 -4.56407 35.0018 7.29943 12.0023 22.4989Z"
                                                                  fill="currentColor"/>
                                                        </svg>}

                                                </button>}


                                                {
                                                    state.isUserAdmin &&
                                                    <Button color="primary" style={{backgroundColor: '#fff'}} className="btn btn-outline-secondary download-btn" onClick={this.downloadDeal}><i className="fa fa-download"/></Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <p className="d-flex" style={{fontSize: 16}}>
                                        {state.guru_id === 0 && this.context.isAdmin &&
                                            <span style={{backgroundColor: 'transparent', border: '1px solid black', fontSize: 14, marginRight: 10}}
                                                  className="badge text-dark me-3">
                <i style={{color: 'gold'}} className="fas fa-star"/> Premium Deal
              </span>}
                                        {state.completed === 2 && this.context.isAdmin &&
                                            <span style={{backgroundColor: 'transparent', border: '1px solid black', fontSize: 14, marginRight: 10}}
                                                  className="badge text-dark me-3">
                <i style={{color: 'gold'}} className="fas fa-star"/> Private Deal
              </span>}
                                        {state.isConcierge &&
                                            <span style={{backgroundColor: '#58ae3a', border: '1px solid black', fontSize: 14}}
                                                  className="badge text-dark me-3">
                <i style={{color: 'gold'}} className="fa-solid fa-crown"/> Concierge Deal
              </span>}
                                        {state.keywords === 'FSBO' &&
                                            <span style={{backgroundColor: 'transparent', border: '1px solid black', fontSize: 14}}
                                                  className={`badge text-dark me-3`}>
                <i style={{color: '#3890da'}} className="far fa-gem"></i>{` Proprietary Deal`}
              </span>}
                                        {state.seller_financing &&
                                            <span style={{backgroundColor: 'transparent', border: '1px solid black', fontSize: 14}}
                                                  className={`badge text-dark ${state.guru_id === 0 ? 'me-1' : ''}`}>
                Seller Financing Available
              </span>}
                                    </p>
                                    <div className="row row-gutter-10 mb-3">
                                        <div className="col-auto mb-2">
                                            {state.brokerlocation_address && <div className="">
                <span className="me-1">
                  <svg className="icon" style={{color: '#0F4778'}} viewBox="0 0 18 24" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z"
      fill="currentColor"/>
</svg>

                </span>
                                                <span style={{color: '#0F4778'}}>{state.brokerlocation_address}</span>
                                            </div>}
                                        </div>

                                        {state.industries.map(ind => <div className="col-auto d-flex mb-2 align-items-center">
                <span className="me-1">
                  <svg className="icon" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M0 17.75C0 18.3467 0.237053 18.919 0.65901 19.341C1.08097 19.7629 1.65326 20 2.25 20H21.75C22.3467 20 22.919 19.7629 23.341 19.341C23.7629 18.919 24 18.3467 24 17.75V8.75H22.5V17.75C22.5 17.9489 22.421 18.1397 22.2803 18.2803C22.1397 18.421 21.9489 18.5 21.75 18.5H2.25C2.05109 18.5 1.86032 18.421 1.71967 18.2803C1.57902 18.1397 1.5 17.9489 1.5 17.75V8.75H0V17.75Z"
                            fill="currentColor"/>
                      <path fillRule="evenodd" clipRule="evenodd"
                            d="M0 5.75C0 5.15326 0.237053 4.58097 0.65901 4.15901C1.08097 3.73705 1.65326 3.5 2.25 3.5H21.75C22.3467 3.5 22.919 3.73705 23.341 4.15901C23.7629 4.58097 24 5.15326 24 5.75V9.326L12.579 12.371C12.1996 12.472 11.8004 12.472 11.421 12.371L0 9.326V5.75ZM2.25 5C2.05109 5 1.86032 5.07902 1.71967 5.21967C1.57902 5.36032 1.5 5.55109 1.5 5.75V8.174L11.8065 10.922C11.9333 10.9559 12.0667 10.9559 12.1935 10.922L22.5 8.174V5.75C22.5 5.55109 22.421 5.36032 22.2803 5.21967C22.1397 5.07902 21.9489 5 21.75 5H2.25ZM7.5 2.75C7.5 2.15326 7.73705 1.58097 8.15901 1.15901C8.58097 0.737053 9.15326 0.5 9.75 0.5H14.25C14.8467 0.5 15.419 0.737053 15.841 1.15901C16.2629 1.58097 16.5 2.15326 16.5 2.75V3.5H15V2.75C15 2.55109 14.921 2.36032 14.7803 2.21967C14.6397 2.07902 14.4489 2 14.25 2H9.75C9.55109 2 9.36032 2.07902 9.21967 2.21967C9.07902 2.36032 9 2.55109 9 2.75V3.5H7.5V2.75Z"
                            fill="currentColor"/>
                  </svg>
                </span>
                                            <span>
                  {ind.industry.name}
                </span>
                                        </div>)}

                                    </div>
                                    <div className="row row-gutter-20">


                                    </div>
                                    <div className="row row-gutter-20">
                                        <div className="col-md-auto mb-3">
                      <span className="text-primary-blue" style={{
                          fontSize: 24,
                          fontWeight: 600
                      }}>Price: {(!state.hide_price && state.brokerprice) ? state.guru_id === 0 && !state.loggedIn ? state.price : state.brokerprice : 'Not Specified'}</span>
                                        </div>
                                    </div>
                                    <div className="row row-gutter-sm-50 ">
                                        <div className="col-6 col-md-auto mb-3">
                                            <p>
                                                <strong className="title-1" style={{color: '#6D7277', fontSize: 14}}>Revenue:
                                                    <span className="text-secondary vertical-align-text-bottom" data-toggle="tooltip"
                                                          data-placement="top" title="Annual sales / revenue generated by the company">
                    <svg className="icon imargin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                            fill="currentColor"/>
                      <path
                          d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                          fill="currentColor"/>
                    </svg>
                  </span>
                                                </strong>
                                                <br/>
                                                <span style={{
                                                    fontWeight: 500,
                                                    fontSize: 24
                                                }}>{state.keywords === 'FSBO' && state.revenue ? '≈' : ''}{state.revenue || '-'}</span>
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-auto mb-3">
                                            <p>
                                                <strong className="title-1" style={{color: '#6D7277', fontSize: 14}}>SDE:
                                                    <span className="text-secondary vertical-align-text-bottom" data-toggle="tooltip"
                                                          data-placement="top"
                                                          title="Annual SDE = seller's discretionary earnings and is a common measure of cash flow used by entrepreneurs looking to acquire and operate a business full-time themselves. Generally speaking, businesses with under $600k annual cash flow will be valued using SDE.">
                    <svg className="icon imargin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                            fill="currentColor"/>
                      <path
                          d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                          fill="currentColor"/>
                    </svg>
                  </span>
                                                </strong>
                                                <br/>
                                                <span style={{
                                                    fontWeight: 500,
                                                    fontSize: 24
                                                }}>{state.keywords === 'FSBO' && state.cashflow ? '≈' : ''}{state.cashflow || '-'}</span>
                                            </p>
                                        </div>
                                        <div className="col-6 col-md-auto mb-3">
                                            <p>
                                                <strong className="title-1" style={{color: '#6D7277', fontSize: 14}}>EBITDA:
                                                    <span className="text-secondary vertical-align-text-bottom" data-toggle="tooltip"
                                                          data-placement="top"
                                                          title="Annual EBITDA = earnings before interest, taxes, depreciation, and amortization and is a common measure of cash flow used by investors to value larger businesses with over $600k annual cash flow (generally speaking).">
                    <svg className="icon imargin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                            fill="currentColor"/>
                      <path
                          d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                          fill="currentColor"/>
                    </svg>
                  </span>
                                                </strong>
                                                <br/>
                                                <span style={{
                                                    fontWeight: 500,
                                                    fontSize: 24
                                                }}>{state.keywords === 'FSBO' && state.ebitda ? '≈' : ''}{state.ebitda || '-'}</span>
                                            </p>
                                        </div>
                                    </div>


                                    {(state.guru_id !== 0 && state.keywords !== 'FSBO') &&
                                        <div className="row align-items-center no-gutters pt-3 pb-4">
                                            <a style={{color: 'inherit', cursor: state.loggedIn ? `pointer` : 'default'}}
                                               href={state.loggedIn ? `/profile/${state.guru_id}` : '#'}
                                               target={state.loggedIn ? `_blank` : '_self'}>
                                                <div className="col-auto">
                                                    <div className="listing-info-card__seller">
                                                        <div className="row align-items-center row-gutter-15">
                                                            {state.keywords !== 'FSBO' && <div className="col-2">
                                                                <img src={profile_pic}
                                                                     ref={img => this.img = img} onError={
                                                                    (e) => {
                                                                        //@ts-ignore
                                                                        if (!e.target.src.includes('images/logo-big.png')) {
                                                                            this.img.src = `/images/logo-big.png`;
                                                                        }
                                                                    }
                                                                } alt=""/>
                                                            </div>}
                                                            <div className="col-10">
                                                                <div className="d-flex mb-2">
                        <span className="me-1"><svg className="icon" viewBox="0 0 12 14" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2 0H10C10.5304 0 11.0391 0.210714 11.4142 0.585786C11.7893 0.960859 12 1.46957 12 2V12C12 12.5304 11.7893 13.0391 11.4142 13.4142C11.0391 13.7893 10.5304 14 10 14H2C1.46957 14 0.960859 13.7893 0.585786 13.4142C0.210714 13.0391 0 12.5304 0 12V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0V0ZM2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V12C1 12.2652 1.10536 12.5196 1.29289 12.7071C1.48043 12.8946 1.73478 13 2 13H10C10.2652 13 10.5196 12.8946 10.7071 12.7071C10.8946 12.5196 11 12.2652 11 12V2C11 1.73478 10.8946 1.48043 10.7071 1.29289C10.5196 1.10536 10.2652 1 10 1H2Z"
          fill="currentColor"/>
    <path d="M2.5 4.5L9.5 4.5L9.5 11.5H2.5V4.5Z" stroke="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M2 2.5C2 2.36739 2.05268 2.24021 2.14645 2.14645C2.24021 2.05268 2.36739 2 2.5 2H7.5C7.63261 2 7.75979 2.05268 7.85355 2.14645C7.94732 2.24021 8 2.36739 8 2.5C8 2.63261 7.94732 2.75979 7.85355 2.85355C7.75979 2.94732 7.63261 3 7.5 3H2.5C2.36739 3 2.24021 2.94732 2.14645 2.85355C2.05268 2.75979 2 2.63261 2 2.5Z"
          fill="currentColor"/>
</svg>
</span>
                                                                    {state.keywords === 'FSBO' ? <>Business profile posted directly by
                                                                            entrepreneur</>
                                                                        :
                                                                        <span>Listed by {state.onlyfirstname}{state.guru_id === 0 && state.brokerName ? state.brokerName.split(' ')[0] : ''}</span>}
                                                                </div>
                                                                {state.responseTime && <div className="d-flex" style={{ fontSize: 12 }}>
                        <span className="me-1"><svg className="icon" viewBox="0 0 16 16" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8Z"
          fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M7.5 3C7.63261 3 7.75979 3.05268 7.85355 3.14645C7.94732 3.24021 8 3.36739 8 3.5V8.71L11.248 10.566C11.3598 10.6334 11.4408 10.7419 11.4736 10.8683C11.5065 10.9946 11.4886 11.1288 11.4238 11.2422C11.3591 11.3556 11.2525 11.4391 11.127 11.4749C11.0014 11.5108 10.8669 11.4961 10.752 11.434L7.252 9.434C7.17547 9.39029 7.11186 9.32712 7.06761 9.25091C7.02335 9.17469 7.00003 9.08813 7 9V3.5C7 3.36739 7.05268 3.24021 7.14645 3.14645C7.24021 3.05268 7.36739 3 7.5 3Z"
          fill="currentColor"/>
</svg>
</span>
                                                                    <span>{state.responseTime}. {
                                                                        state.responseTimeRaw > 24 ?
                                                                            <span>
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation()
                                                        this.setState({showMessagesBlock: true});
                                                    }}>View</a> message thread for more contact options.
                                                  </span>
                                                                            : ''
                                                                    }</span>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>

                                        </div>}
                                    {state.loggedIn && <div className="d-flex align-items-center justify-content-between">
                                        <p className="text-secondary-2 text-sm mb-0">Updated {moment(state.listing_date).format('LL')}</p>
                                        <div className="">
                                            <div data-toggle="tooltip" data-placement="top"
                                                 title="Report for Abuse or Mark as No Longer Available"
                                                 onClick={() => this.setState({abuseModal: true})}
                                                 className="text-secondary-2 text-sm mb-0" style={{cursor: 'pointer'}}>

                                                <i className="fas fa-ban"></i> Report Abuse

                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                <div className="listing-info-card__image position-relative">

                                    <img src={getImageUrl(listingimage, false, '/images/thumbnail-full.jpg')} alt=""/>


                                    <svg className="d-none d-lg-block"
                                         style={{position: 'absolute', top: 0, left: -8, bottom: 0, right: 0}} width="65" height="100%"
                                         viewBox="0 0 65 459" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M 0 -35 H 0 C 1 -34 67 57 65 230.5 C 65 442 1 495 1 496 H 0 V -14 Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>

                        </section>


                        <section className="row row-gutter-20 flex-xl-nowrap mb-5">


                            <article className="layout-content mb-5 mb-xl-0">


                                <div className="content-card">
                                    <>
                                        <div className="content-body">
                                            <h2>Description:</h2>
                                            <p style={{userSelect: 'auto'}}
                                               dangerouslySetInnerHTML={this.createMarkup(state.brokerdescription ? state.brokerdescription.split('\n').join('<br/>') : '')}></p>
                                        </div>
                                        <div className="content-divider"></div>
                                    </>

                                    {(state.priceChange.length > 0 && !state.hide_price) && <div className="content-body mb-4">
                                        {(state.priceChange.length > 0 && !state.hide_price) &&
                                            <div>
                                                <h2>Price History:</h2>
                                                <Table responsive borderless className="">
                                                    <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Price</th>
                                                        <th>Change %</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {state.priceChange.map((p, i) => {
                                                        return (
                                                            <tr>
                                                                <td className="data_row">{moment(p.changed_on).format('LLL')}</td>
                                                                <td className="data_row">{p.price}</td>
                                                                <td className="data_row">{i >= 1 ? priceChangeLabel(p, i) : 'n/a'}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        }
                                    </div>}

                                    {(showHistorical()) &&
                                        <div className="content-body mb-4">
                                            <h2>Historical Data:

                                                {this.state.keywords === 'FSBO' &&
                                                    <span className="text-secondary vertical-align-text-bottom" data-toggle="tooltip"
                                                          data-placement="top"
                                                          title="Financials provided by owner and should not be considered reliable until you sign an NDA & review financial statements verified by a reliable third party.">
                      <svg style={{color: 'green'}} className="icon imargin" viewBox="0 0 20 20" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                              fill="currentColor"/>
                        <path
                            d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                            fill="currentColor"/>
                      </svg>
                   </span>
                                                }
                                            </h2>

                                            <div className="accordion" id="accordion">


                                                {historicalRevenueChart().length > 0 && <div className="card">
                                                    <div className="card-header" id="heading1">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-unstyled justify-content-between w-100"
                                                                    type="button"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapse1"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapse1">
                                                                <span style={{fontWeight: 600}}>Revenue graph</span>
                                                                <span className="collapse-icon collapse-icon--close">
                            <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                      fill="currentColor"/>
                            </svg>
                            </span>
                                                                <span className="collapse-icon collapse-icon--open">
                            <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                      fill="currentColor"/>
                            </svg>
                            </span>
                                                            </button>
                                                        </h2>
                                                    </div>

                                                    <div id="collapse1"
                                                         className="collapse show"
                                                         aria-labelledby="heading1"
                                                    >
                                                        <div className="card-body">
                                                            <div className="chart-container">

                                                                <Line data={{
                                                                    labels: historicalRevenueChart().map(h => h.year),
                                                                    datasets: [{
                                                                        label: "",
                                                                        data: historicalRevenueChart().map(h => h.revenue),
                                                                        backgroundColor:
                                                                            "#FAFAFA",
                                                                        borderColor:
                                                                            "#3890DA",
                                                                        pointBackgroundColor: "#829FB9",
                                                                        pointBorderColor: "#829FB9",
                                                                        borderWidth: 1,
                                                                        tension: 0.4,
                                                                        cubicInterpolationMode: 'monotone',
                                                                        fill: true,
                                                                    }],
                                                                }}
                                                                       options={chartOptions}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {historicalCashflowChart().length > 0 && <div className="card">
                                                    <div className="card-header" id="heading2">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-unstyled justify-content-between w-100"
                                                                    type="button"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapse2"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapse2">
                                                                <span style={{fontWeight: 600}}>SDE graph</span>
                                                                <span className="collapse-icon collapse-icon--close">
                            <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                      fill="currentColor"/>
                            </svg>
                            </span>
                                                                <span className="collapse-icon collapse-icon--open">
                            <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                      fill="currentColor"/>
                            </svg>
                            </span>
                                                            </button>
                                                        </h2>
                                                    </div>

                                                    <div id="collapse2"
                                                         className="collapse show"
                                                         aria-labelledby="heading2"
                                                    >
                                                        <div className="card-body px-0">
                                                            <div className="card-body">
                                                                <div className="chart-container">

                                                                <Line data={{
                                                                    labels: historicalCashflowChart().map(h => h.year),
                                                                    datasets: [{
                                                                        label: "",
                                                                        data: historicalCashflowChart().map(h => h.cashflow),
                                                                        backgroundColor:
                                                                            "#FAFAFA",
                                                                        borderColor:
                                                                            "#3890DA",
                                                                        pointBackgroundColor: "#829FB9",
                                                                        pointBorderColor: "#829FB9",
                                                                        borderWidth: 1,
                                                                        tension: 0.4,
                                                                        cubicInterpolationMode: 'monotone',
                                                                        fill: true,
                                                                    }],
                                                                }}
                                                                       options={chartOptions}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                                {historicalEBITDAChart().length > 0 && <div className="card">
                                                    <div className="card-header" id="heading3">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-unstyled justify-content-between w-100"
                                                                    type="button"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapse3"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapse3">
                                                                <span style={{fontWeight: 600}}>EBIDTA graph</span>
                                                                <span className="collapse-icon collapse-icon--close">
                            <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                      fill="currentColor"/>
                            </svg>
                            </span>
                                                                <span className="collapse-icon collapse-icon--open">
                            <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                      fill="currentColor"/>
                            </svg>
                            </span>
                                                            </button>
                                                        </h2>
                                                    </div>

                                                    <div id="collapse3"
                                                         className="collapse show"
                                                         aria-labelledby="heading3"
                                                    >
                                                        <div className="card-body">
                                                            <div className="chart-container">

                                                                <Line data={{
                                                                    labels: historicalEBITDAChart().map(h => h.year),
                                                                    datasets: [{
                                                                        label: "",
                                                                        data: historicalEBITDAChart().map(h => h.ebitda),
                                                                        backgroundColor:
                                                                            "#FAFAFA",
                                                                        borderColor:
                                                                            "#3890DA",
                                                                        pointBackgroundColor: "#829FB9",
                                                                        pointBorderColor: "#829FB9",
                                                                        borderWidth: 1,
                                                                        tension: 0.4,
                                                                        cubicInterpolationMode: 'monotone',
                                                                        fill: true,
                                                                    }],
                                                                }}
                                                                       options={chartOptions}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                                <div className="card">
                                                    <div className="card-header" id="heading3">
                                                        <h2 className="mb-0">
                                                            <button className="btn btn-unstyled justify-content-between w-100"
                                                                    type="button"
                                                                    data-toggle="collapse"
                                                                    data-target="#collapse4"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapse4">
                                                                <span style={{fontWeight: 600}}>Historical Data Table View</span>
                                                                <span className="collapse-icon collapse-icon--close">
                            <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12Z"
                                      fill="currentColor"/>
                            </svg>
                            </span>
                                                                <span className="collapse-icon collapse-icon--open">
                            <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 5.25C12.1989 5.25 12.3897 5.32902 12.5303 5.46967C12.671 5.61032 12.75 5.80109 12.75 6V12C12.75 12.1989 12.671 12.3897 12.5303 12.5303C12.3897 12.671 12.1989 12.75 12 12.75H6C5.80109 12.75 5.61032 12.671 5.46967 12.5303C5.32902 12.3897 5.25 12.1989 5.25 12C5.25 11.8011 5.32902 11.6103 5.46967 11.4697C5.61032 11.329 5.80109 11.25 6 11.25H11.25V6C11.25 5.80109 11.329 5.61032 11.4697 5.46967C11.6103 5.32902 11.8011 5.25 12 5.25Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M11.25 12C11.25 11.8011 11.329 11.6103 11.4697 11.4697C11.6103 11.329 11.8011 11.25 12 11.25H18C18.1989 11.25 18.3897 11.329 18.5303 11.4697C18.671 11.6103 18.75 11.8011 18.75 12C18.75 12.1989 18.671 12.3897 18.5303 12.5303C18.3897 12.671 18.1989 12.75 18 12.75H12.75V18C12.75 18.1989 12.671 18.3897 12.5303 18.5303C12.3897 18.671 12.1989 18.75 12 18.75C11.8011 18.75 11.6103 18.671 11.4697 18.5303C11.329 18.3897 11.25 18.1989 11.25 18V12Z"
                                      fill="currentColor"/>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5ZM12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24Z"
                                      fill="currentColor"/>
                            </svg>
                            </span>
                                                            </button>
                                                        </h2>
                                                    </div>

                                                    <div id="collapse4"
                                                         className="collapse"
                                                         aria-labelledby="heading4"
                                                    >
                                                        <div className="card-body">
                                                            <Table responsive bordered className="text-center">
                                                                <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    {state.historical.map((h, i) => {
                                                                        return (
                                                                            <th style={{fontWeight: 600}}>{h.year}</th>
                                                                        )
                                                                    })}
                                                                    <th style={{fontWeight: 600}}>TTM</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <th><span style={{fontWeight: 600}}>Revenue</span></th>
                                                                    {state.historical.map((h, i) =>
                                                                        <td>{h.revenue}</td>
                                                                    )}
                                                                    <td>{state.ttm.revenue || 'n/a'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th><span style={{fontWeight: 600}}>SDE</span></th>
                                                                    {state.historical.map((h, i) =>
                                                                        <React.Fragment>
                                                                            <td>{h.cashflow}</td>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <td>{state.ttm.cashflow || 'n/a'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th><span style={{fontWeight: 600}}>EBITDA</span></th>
                                                                    {state.historical.map((h, i) =>
                                                                        <React.Fragment>
                                                                            <td>{h.ebitda}</td>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <td>{state.ttm.ebitda || 'n/a'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th><span style={{fontWeight: 600}}>Revenue Growth</span></th>
                                                                    {state.historical.map((h, i) =>
                                                                        <React.Fragment>
                                                                            <td>{i >= 1 ? `${((parseInt(h.revenue.replace(/,/g, ''))) / parseInt(state.historical[i - 1].revenue.replace(/,/g, '')) * 100 - 100).toFixed(1)}%`.replace('NaN', '0') : ''}</td>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <td>{state.ttm.revenue && state.historical.length ? `${((parseInt(state.ttm.revenue.replace(/,/g, ''))) / parseInt(state.historical[state.historical.length - 1].revenue.replace(/,/g, '')) * 100 - 100).toFixed(1)}%`.replace('NaN', '0') : 'n/a'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th><span style={{fontWeight: 600}}>SDE Margin</span></th>
                                                                    {state.historical.map((h, i) =>
                                                                        <React.Fragment>
                                                                            <td>{`${((parseInt(h.cashflow.replace(/,/g, ''))) / parseInt(h.revenue.replace(/,/g, '')) * 100).toFixed(1)}%`.replace('NaN', '0')}</td>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <td>{state.ttm.cashflow && state.ttm.revenue ? `${((parseInt(state.ttm.cashflow.replace(/,/g, ''))) / parseInt(state.ttm.revenue.replace(/,/g, '')) * 100).toFixed(1)}%`.replace('NaN', '0') : 'n/a'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th><span style={{fontWeight: 600}}>EBITDA Margin</span></th>
                                                                    {state.historical.map((h, i) =>
                                                                        <React.Fragment>
                                                                            <td>{`${((parseInt(h.ebitda.replace(/,/g, ''))) / parseInt(h.revenue.replace(/,/g, '')) * 100).toFixed(1)}%`.replace('NaN', '0')}</td>
                                                                        </React.Fragment>
                                                                    )}
                                                                    <td>{state.ttm.ebitda && state.ttm.revenue ? `${((parseInt(state.ttm.ebitda.replace(/,/g, ''))) / parseInt(state.ttm.revenue.replace(/,/g, '')) * 100).toFixed(1)}%`.replace('NaN', '0') : 'n/a'}</td>
                                                                </tr>
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>


                                        </div>
                                    }


                                    <div className="position-relative">

                                        <div
                                            className={state.loggedIn ? "" : "d-flex align-items-center justify-content-center overlay-container"}>
                                            <div className="text-center">
                                                {!this.isLoggedIn() && <p className="font-weight-bold">
                    <span><svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M17.25 10.5H6.75C5.09315 10.5 3.75 11.8431 3.75 13.5V21C3.75 22.6569 5.09315 24 6.75 24H17.25C18.9069 24 20.25 22.6569 20.25 21V13.5C20.25 11.8431 18.9069 10.5 17.25 10.5Z"
        fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M6.75 6C6.75 4.60761 7.30312 3.27226 8.28769 2.28769C9.27226 1.30312 10.6076 0.75 12 0.75C13.3924 0.75 14.7277 1.30312 15.7123 2.28769C16.6969 3.27226 17.25 4.60761 17.25 6V10.5H15.75V6C15.75 5.00544 15.3549 4.05161 14.6517 3.34835C13.9484 2.64509 12.9946 2.25 12 2.25C11.0054 2.25 10.0516 2.64509 9.34835 3.34835C8.64509 4.05161 8.25 5.00544 8.25 6V10.5H6.75V6Z"
          fill="currentColor"/>
</svg>
</span>
                                                    Create an account to contact seller
                                                </p>}
                                                {!this.isLoggedIn() &&
                                                    <a href="/signup" onClick={this.goToLoginPage} className="btn btn-green px-4">Request
                                                        Access</a>}
                                            </div>
                                        </div>

                                        <div style={{minHeight: 100}} className={state.loggedIn ? `row mb-4` : 'row mb-4 blurred'}>
                                            {(state.referencenumber !== null && state.referencenumber !== '' && !state.referencenumber.startsWith('internal_')) &&
                                                <div className="col-sm-12 mb-3"><p>
                                                    <strong className="title-1">Reference Number:</strong>
                                                    <br/>
                                                    {state.referencenumber}
                                                </p></div>}


                                            {(state.nda !== null && state.nda !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}>
                                                    <strong className="title-1">Sign the NDA now:</strong>
                                                    <br/>
                                                    <a href={state.nda} target="_blank">Link</a>
                                                </div>}


                                            {(state.keywords === 'FSBO' && state.want_to_sell) &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Wants to sell a business:</strong>
                                                    <br/>
                                                    {state.want_to_sell}
                                                </p></div>}


                                            {(state.included_in_asking_price !== null && state.included_in_asking_price !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Inventory included in asking price:</strong>
                                                    <br/>
                                                    {state.included_in_asking_price ? 'Yes' : 'No'}
                                                </p></div>}


                                            {(state.brokerongoing_support !== null && state.brokerongoing_support !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Ongoing-support:</strong>
                                                    <br/>
                                                    {state.brokerongoing_support}
                                                </p></div>}

                                            {(state.brokergrowth_opportunity !== null && state.brokergrowth_opportunity !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Growth Opportunity:</strong>
                                                    <br/>
                                                    {state.brokergrowth_opportunity}
                                                </p></div>}

                                            {(state.brokercompetition !== null && state.brokercompetition !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Competition:</strong>
                                                    <br/>
                                                    {state.brokercompetition}
                                                </p></div>}

                                            {(state.brokerfinancing !== null && state.brokerfinancing !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Financing:</strong>
                                                    <br/>
                                                    {state.brokerfinancing}
                                                </p></div>}

                                            {(state.seller_financing !== null) &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Seller Financing Available:</strong>
                                                    <br/>
                                                    {state.seller_financing ? 'Yes' : 'No'}
                                                </p></div>}

                                            {(state.brokerfacilities !== null && state.brokerfacilities !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Facilities/Assets Overview:</strong>
                                                    <br/>
                                                    {state.brokerfacilities}
                                                </p></div>}

                                            {(state.brokerreason_for_selling !== null && state.brokerreason_for_selling !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Reason for Selling:</strong>
                                                    <br/>
                                                    {state.brokerreason_for_selling}
                                                </p></div>}

                                            {(state.target_markets !== null && state.target_markets !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Target Markets:</strong>
                                                    <br/>
                                                    {state.target_markets}
                                                </p></div>}

                                            {(state.video != null && state.video !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}>
                                                    <p>
                                                        <strong className="title-1">Video:</strong>
                                                        <br/>
                                                        <p dangerouslySetInnerHTML={this.createMarkup(state.video)}></p>
                                                    </p>
                                                </div>}

                                            {(state.modified_at !== null && state.modified_at !== '') &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Last update:</strong>
                                                    <br/>
                                                    {moment(state.modified_at).format('LLL')}
                                                </p></div>}

                                            {(state.teaser) && <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                <strong className="title-1">Teaser:</strong>
                                                <br/>
                                                {state.teaser}
                                            </p></div>}

                                            {(state.competitive_advantages) &&
                                                <div className="col-sm-12 mb-3" style={{wordWrap: 'break-word'}}><p>
                                                    <strong className="title-1">Competitive Advantages:</strong>
                                                    <br/>
                                                    {state.competitive_advantages}
                                                </p></div>}

                                            {(state.competitive_advantages) && <div className="col-sm-12 mb-3"><p>
                                                <strong className="title-1">How many people have favorited this listing:</strong>
                                                <br/>
                                                {state.favoriteCount}
                                            </p></div>}

                                            <div className="col-sm-12 mb-3"><p>
                                                <strong className="title-1">Days on BizNexus:</strong>
                                                <br/>
                                                {moment().diff(state.created_at, 'days')}
                                            </p></div>

                                            {(state.stats && state.stats.views > 10) && <div className="col-sm-12 mb-3"><p>
                                                <strong className="title-1">Views:</strong>
                                                <br/>
                                                {state.stats ? state.stats.views > 25 ? '25+' : state.stats.views : '0'}
                                            </p></div>}


                                        </div>

                                        {(state.faq.length > 0 && state.loggedIn) && <div className="content-body mb-4"><h2>FAQ</h2>
                                            {state.faq.map((f) =>
                                                <React.Fragment>
                                                    <h6>{f.q}:</h6>
                                                    <p dangerouslySetInnerHTML={this.createMarkup(f.a ? f.a.split('\n').join('<br/>') : '')}/>
                                                </React.Fragment>
                                            )}
                                        </div>}

                                    </div>
                                    <div className="position-relative text-center mb-4">
                                        <div className="background-divider"/>
                                        {(this.isLoggedIn() && state.contactSellerButton && state.showMessagesBlock === false) &&
                                            <button onClick={this.onContactSeller}
                                                    className="btn btn-primary-blue position-relative mw-350px w-100">Contact
                                                Seller {renderNewTabIcon()}</button>}
                                    </div>

                                    <div className="content-body" style={{padding: 10}}>
                                        <p className="text-secondary-2 text-sm">The information in this listing has been created by the
                                            business seller or representative. BizNexus is not suggesting, endorsing affiliation of any kind
                                            with the business seller or seller representative stated above. BizNexus has not independently
                                            verified any of the information about the business, and assumes no responsibility for its accuracy
                                            or completeness. Read BizNexus’s <a href="https://biznexus.com/terms-of-use/">Terms of
                                                Use</a> before responding to any posting.</p>
                                    </div>

                                </div>
                            </article>


                            <aside className="layout-sidebar">

                                {(state.guru_id !== 0 && state.loggedIn && state.keywords !== 'FSBO') && <div className="">
                                    {(state.contactSellerButton && state.showMessagesBlock === false) &&
                                        <button onClick={this.onContactSeller} className="btn btn-primary-blue w-100 mb-4">Contact
                                            Seller {renderNewTabIcon()}</button>}

                                    {renderBrokerContact()}

                                </div>}

                                {(state.guru_id === 0 && state.loggedIn) && <div className="">
                                    {(state.contactSellerButton && state.showMessagesBlock === false) &&
                                        <button onClick={this.onContactSeller} className="btn btn-primary-blue w-100 mb-4">Contact
                                            Seller {renderNewTabIcon()}</button>}

                                    {renderBrokerContact()}

                                </div>}

                                {(state.keywords === 'FSBO' && state.loggedIn) && <div className="">
                                    {(state.contactSellerButton && state.showMessagesBlock === false) &&
                                        <button onClick={this.onContactSeller} className="btn btn-primary-blue w-100 mb-4">Contact
                                            Owner {renderNewTabIcon()}</button>}

                                    {renderBrokerContact()}

                                </div>}

                                <div className="row mb-4">
                                    <div className="col-6 mb-2">
                                        <p>
                                            <a
                                                style={{width: '100%'}}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.biznexus.com/biznexus-concierge-for-providers"
                                                className="btn btn-sm btn-outline-primary-blue">Concierge
                                            </a>

                                        </p>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <p>
                                            <a
                                                style={{width: '100%'}}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.biznexus.com/acquisition-financing"
                                                className="btn btn-sm btn-outline-primary-blue">Financing
                                            </a>

                                        </p>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <p>
                                            <a
                                                style={{width: '100%'}}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.biznexus.com/due-diligence"
                                                className="btn btn-sm btn-outline-primary-blue">Due Diligence
                                            </a>

                                        </p>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <p>
                                            <a
                                                style={{width: '100%'}}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://www.fastcto.com/services/due-diligence"
                                                className="btn btn-sm btn-outline-primary-blue">Tech Diligence
                                            </a>

                                        </p>
                                    </div>
                                </div>

                                {(state.guru_id === 0 && !state.loggedIn) && <div className="d-xl-block">
                                    <div className="mb-4" style={{textAlign: 'center'}}>
                                        <div className="financial-card">
                                            <div className="financial-card__header">
                                                <h2 className="h2 mb-0">Contact Seller</h2>
                                            </div>

                                            <div className={`financial-card__content position-relative text-sm`}>


                                                <div>
                                                    <p><i className="fas fa-lock"></i> Create an account to view full details</p>
                                                    <p>To prevent SPAM, we require users to sign in before sending a message</p>
                                                    <p><a href="/signup" onClick={this.goToLoginPage}
                                                          className="btn btn-primary-blue w-100 mb-4">Sign in</a></p>
                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>}


                                <div className="d-xl-block">


                                    <div className="mb-1">
                                        <div className="financial-card">
                                            <div className="financial-card__header">
                                                <h2 className="h2 mb-0">Financial Metrics & Multiples</h2>
                                            </div>

                                            <div className={`financial-card__content position-relative`}>
                                                <div
                                                    className={state.loggedIn ? "" : "d-flex align-items-center justify-content-center overlay-container"}>
                                                    {!this.isLoggedIn() &&
                                                        <a href="/signup" onClick={this.goToLoginPage} className="btn btn-green px-4">Create an
                                                            account to view financials</a>}
                                                </div>
                                                <div className={`${state.loggedIn ? '' : 'blurred'}`}>
                                                    <p className="mb-2">
                                                        <span className="font-weight-500 text-sm">Revenue Multiple:</span>
                                                        <span
                                                            className="text-primary-blue text-sm"> {state.revenue_multiple ? state.revenue_multiple.toFixed(2) + 'X' : 'n/a'}</span>
                                                        <span className="text-secondary vertical-align-text-bottom" data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="The revenue multiple is a financial ratio that compares a company's asking price to its annual revenue.">
                    <svg className="icon imargin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
      fill="currentColor"/>
<path
    d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
    fill="currentColor"/>
</svg>
                  </span>
                                                    </p>
                                                    <p className="mb-2">
                                                        <span className="font-weight-500 text-sm">EBITDA Multiple: </span>
                                                        <span
                                                            className="text-primary-blue text-sm">{state.ebitda_multiple ? state.ebitda_multiple.toFixed(2) + 'X' : 'n/a'}</span>
                                                        <span className="text-secondary vertical-align-text-bottom" data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="The EBITDA multiple is a financial ratio that compares a company's asking price to its annual EBITDA.">
                    <svg className="icon imargin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
      fill="currentColor"/>
<path
    d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
    fill="currentColor"/>
</svg>

                  </span>
                                                    </p>
                                                    <p className="mb-2">
                                                        <span className="font-weight-500 text-sm">SDE Multiple: </span>
                                                        <span
                                                            className="text-primary-blue text-sm">{state.cashflow_multiple ? state.cashflow_multiple.toFixed(2) + 'X' : 'n/a'}</span>
                                                        <span className="text-secondary vertical-align-text-bottom" data-toggle="tooltip"
                                                              data-placement="top"
                                                              title="The sellers discretionary earnings (SDE) / cash flow multiple is a financial ratio that compares a company's asking price to the owners total annual cash flow including salary, benefits & depreciation.">
                    <svg className="icon imargin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd"
      d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
      fill="currentColor"/>
<path
    d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
    fill="currentColor"/>
</svg>

                  </span>
                                                    </p>

                                                    <p className="mb-2">
                                                        <span className="font-weight-500 text-sm">FF&E: </span>
                                                        <span className="text-primary-blue text-sm">{state.brokerppe || 'n/a'}</span>
                                                    </p>
                                                    <p className="mb-2">
                                                        <span className="font-weight-500 text-sm">Inventory: </span>
                                                        <span className="text-primary-blue text-sm">{state.brokerinventory || 'n/a'}</span>
                                                    </p>
                                                    <p className="mb-2">
                                                        <span className="font-weight-500 text-sm">Real Estate Price: </span>
                                                        <span className="text-primary-blue text-sm">{state.real_estate || 'n/a'}</span>
                                                    </p>

                                                    {state.real_estate && <p className="mb-2">
                                                        <span className="font-weight-500 text-sm">Real Estate: </span>
                                                        <span
                                                            className="text-primary-blue text-sm">{state.leased_or_owned === 1 ? 'Leased' : 'Owned'}</span>
                                                    </p>}
                                                </div>
                                            </div>


                                            {/* <div className="financial-card__header">
                  <h2 className="h2 mb-0">My Private Notes
            <span className="text-secondary vertical-align-text-bottom" data-toggle="tooltip" data-trigger="hover focus" data-placement="top" data-html="true" title={`Private notes are visible only to you. You can see a summary of all of your outstanding private notes/tasks <a href="/user/favorites/notes">HERE</a>`}>
                    <svg className="icon imargin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z" fill="currentColor"/>
                      <path d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z" fill="currentColor"/>
                    </svg>
                  </span></h2>
                </div> */}

                                            {/* <div className="financial-card__body position-relative" style={{ padding: '30px 20px 35px' }}>
                  {state.listingNote.map(note => (
                    <div className="mb-3">
                    <div className={`chat-body__message chat-body__message--received`} style={{ maxWidth: '100%' }}>
                        <div style={{ width: '100%' }}>
                        <p className="chat-body__message-meta">{note.created_at ? moment(new Date(note.created_at)).fromNow(): null}
                        <span onClick={() => this.deleteNote(note.id)} style={{ float: 'right', cursor: 'pointer' }}><i className="fas fa-trash-alt"></i></span>
                        </p>
                        <div className="chat-body__message-body">
                            <p>{note.body}</p>
                        </div>
                        </div>
                    </div>
                    </div>
                  ))}
            
                  <div className="mb-4">
                    <label htmlFor="note" className="sr-only">Note</label>
                    <textarea
                    value={state.listingNoteText}
                    onChange={(e) => {
                      const body = e.target.value;
                      this.setState({
                        listingNoteText: body,
                      });
                    }}
                    className="form-control mb-3" placeholder="Enter your own confidential, private note about this deal here." name="note" id="note" rows={3}></textarea>
                    <button className="btn btn-primary px-5 w-100" onClick={this.updateNote}>Add New Note</button>
                  </div>

                </div> */}


                                        </div>

                                    </div>

                                    {state.loggedIn && <div className="financial-card mb-2 mt-2" style={{backgroundColor: '#fff'}}>

                                        <h2 className="financial-card__header h2 mb-0"><a href="/user/favorites">My Notes</a>
                                            <span id="notesTooltip" className="text-secondary vertical-align-text-bottom">
                      <svg className="icon imargin" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10 18.75C12.3206 18.75 14.5462 17.8281 16.1872 16.1872C17.8281 14.5462 18.75 12.3206 18.75 10C18.75 7.67936 17.8281 5.45376 16.1872 3.81282C14.5462 2.17187 12.3206 1.25 10 1.25C7.67936 1.25 5.45376 2.17187 3.81282 3.81282C2.17187 5.45376 1.25 7.67936 1.25 10C1.25 12.3206 2.17187 14.5462 3.81282 16.1872C5.45376 17.8281 7.67936 18.75 10 18.75ZM10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20Z"
                              fill="currentColor"/>
                        <path
                            d="M6.5625 7.54125H8.2125C8.2125 6.565 8.785 5.81125 9.9125 5.81125C10.7687 5.81125 11.5538 6.24 11.5538 7.27125C11.5538 8.065 11.0862 8.43 10.3475 8.985C9.50625 9.59625 8.84 10.31 8.8875 11.4688L8.89625 12.0475H10.53V11.6038C10.53 10.7063 10.8713 10.445 11.7925 9.74625C12.5538 9.1675 13.3475 8.525 13.3475 7.17625C13.3475 5.2875 11.7525 4.375 10.0063 4.375C8.34875 4.375 6.52375 5.18375 6.56375 7.54125H6.5625ZM8.515 14.4363C8.515 15.1025 9.04625 15.595 9.7775 15.595C10.5388 15.595 11.0625 15.1025 11.0625 14.4363C11.0625 13.7463 10.5375 13.2613 9.77625 13.2613C9.04625 13.2613 8.515 13.7463 8.515 14.4363Z"
                            fill="currentColor"/>
                      </svg>
              </span>
                                            <UncontrolledTooltip placement="top" target={`notesTooltip`} autohide={false}
                                                                 innerClassName="areas-tooltip">
                                                Private notes are visible only to you.
                                            </UncontrolledTooltip>
                                        </h2>

                                        <div className="financial-card__content position-relative mt-1">
                                            {state.listingNote.map(note => (
                                                <div className="mb-3">
                                                    <div className={`chat-body__message chat-body__message--received`}
                                                         style={{maxWidth: '100%'}}>
                                                        <div style={{width: '100%'}}>
                                                            <p
                                                                className="chat-body__message-meta">{note.created_at ? moment(new Date(note.created_at)).fromNow() : 'a long time ago'}
                                                                <span onClick={() => this.deleteNote(note.id)}
                                                                      style={{float: 'right', cursor: 'pointer'}}><i className="fas fa-trash-alt"></i></span>
                                                            </p>
                                                            <div className="chat-body__message-body" style={{wordBreak: 'break-word'}}>
                                                                <p>{note.body}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="">
                                                <label htmlFor="note" className="sr-only">Note</label>
                                                <Textarea
                                                    value={state.listingNoteText}
                                                    onChange={(e) => {
                                                        const body = e.target.value;
                                                        this.setState({
                                                            listingNoteText: body,
                                                        });
                                                    }}
                                                    style={{resize: 'none'}}
                                                    className="form-control mb-3"
                                                    placeholder="Enter your own confidential, private note about this deal here."
                                                    name="note" id="note" rows={3}/>
                                                <button className="btn btn-primary px-5 w-100" onClick={this.updateNote}>Add New Note
                                                </button>
                                            </div>

                                        </div>

                                    </div>}


                                </div>


                                {/* ad here */}
                                <div className="d-block mt-3">
                                    <Ads
                                        planId={process.env.REACT_APP_ROOT === "https://app.biznexus.com/" ? "plan_HG48hWOLRzdbCr" : "plan_GwIAkUXWswIWLO"}/>
                                </div>

                            </aside>
                        </section>


                        {state.similarList.length > 0 && <section className="similar-businesses-wrapper">
                            <h2 className="h2 mb-4">Similar Businesses For Sale:</h2>
                            <div className="d-md-block mb-4">
                                <div className="horizontal-scroll-layout drag-viewport custom_scroll" style={{width: '100%'}}>
                                    <div style={{width: 550, padding: 10}}>
                                        <div className="row row-gutter-15 flex-md-nowrap drag-content">
                                            {state.similarList.map(resultList => <div className="col-12 col-md-auto mt-1 mb-1">
                                                <div onClick={() => {
                                                    window.open(`/business-for-sale/${resultList.transaction_slug}`, '_blank');
                                                }} className="business-card business-card--horizontal"
                                                     style={{cursor: 'pointer'}}>
                                                    <div className="business-card__image">
                                                        <img src={(resultList.image !== null && resultList.image !== '')
                                                            ? getImageUrl(resultList.image, true)
                                                            : "/images/empty.jpg"
                                                        } alt=""/>
                                                        <div
                                                            className="business-card__price btn btn-secondary">Price: {!resultList.hide_price ? resultList.price : 'Not Specified'}</div>
                                                    </div>
                                                    <div className="business-card__content" style={{height: '100%'}}>
                                                        <h3
                                                            className="business-card__title">{this.truncString(resultList.name, 50, '...')}</h3>
                                                        <p className="business-card__location d-flex">
                            <span className="me-1">
                            {resultList.location_address && <svg className="icon" viewBox="0 0 18 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M9 24C9 24 18 15.471 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948211 11.3869 0 9 0C6.61305 0 4.32387 0.948211 2.63604 2.63604C0.948211 4.32387 3.55683e-08 6.61305 0 9C0 15.471 9 24 9 24ZM9 13.5C10.1935 13.5 11.3381 13.0259 12.182 12.182C13.0259 11.3381 13.5 10.1935 13.5 9C13.5 7.80653 13.0259 6.66193 12.182 5.81802C11.3381 4.97411 10.1935 4.5 9 4.5C7.80653 4.5 6.66193 4.97411 5.81802 5.81802C4.97411 6.66193 4.5 7.80653 4.5 9C4.5 10.1935 4.97411 11.3381 5.81802 12.182C6.66193 13.0259 7.80653 13.5 9 13.5Z"
                                      fill="currentColor"/>
                            </svg>}
                            </span>
                                                            <span>{resultList.location_address}</span>
                                                        </p>
                                                        <p className="business-card__description">
                                                            {this.truncString(resultList.description, 150, '...')}
                                                        </p>
                                                        <p className="mb-1">
                                                            <span className="font-weight-bolder">Revenue: </span>
                                                            <span className="text-primary-blue text-nowrap">{resultList.revenue}</span>
                                                        </p>
                                                        <p className="mb-1">
                                                            <span className="font-weight-bolder">SDE: </span>
                                                            <span className="text-primary-blue text-nowrap">{resultList.cashflow}</span>
                                                        </p>
                                                        <p>
                                                            <span className="font-weight-bolder">EBITDA: </span>
                                                            <span className="text-primary-blue text-nowrap">{resultList.ebitda}</span>
                                                        </p>
                                                        <button style={{bottom: 0, position: 'absolute'}}
                                                                className="btn btn-primary business-card__footer-btn">
                                                            View Details
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>}


                    </div>


                </div>

                <Modal isOpen={state.abuseModal} toggle={() => this.setState({abuseModal: !state.abuseModal})} keyboard={true}
                       backdrop="static" className="modal-dialog-centered delete-item">
                    <ModalHeader toggle={() => this.setState({abuseModal: !state.abuseModal})}>
                        Report This Listing
                    </ModalHeader>
                    <ModalBody>

                        <Row>
                            <Col sm="12">

                                <Row>
                                    <Col sm="6"><b>Listing Title:</b></Col>
                                    <Col sm="6">{state.name}</Col>
                                </Row><br/>
                                <Row>
                                    <Col sm="6"><b>Reported By:</b></Col>
                                    <Col sm="6">{state.buyerName}</Col>
                                </Row> <br/>
                                <Row>
                                    <Col sm="6"><b>Reported On:</b></Col>
                                    <Col sm="6">{moment(new Date()).format('LLL')}</Col>
                                </Row> <br/>

                                <FormGroup>
                                    <Label htmlFor="abuse">Why are you flagging this listing for review?</Label>
                                    <textarea placeholder="Is the deal somehow inappropriate or no longer available?"
                                              style={{width: '100%', height: 'auto'}} rows={6} className="reason_to_join" id="abuse"
                                              onChange={(e) => this.setState({abuseText: e.target.value})}
                                              value={state.abuseText}></textarea>
                                </FormGroup>


                            </Col>

                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={() => this.onAbuseSubmit()}>Submit</Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={state.modal} toggle={this.toggle}>
                    <ModalBody style={{padding: 30}}>
                        <h1 className="h1 mb-4 text-center">Please Sign In To Message Seller</h1>
                        <a href={this.redirectLink('login', state)} className="mb-4 btn btn-primary-blue w-100">Sign In</a>
                        <br/>
                        <p style={{fontStyle: 'italic'}} className="mb-4 text-center">To prevent SPAM, we require users to sign in
                            before sending a message</p>
                    </ModalBody>
                </Modal>


                <Modal isOpen={state.premiumModal} toggle={this.premiumToggle}>
                    <ModalBody>
                        <div style={{textAlign: 'center'}}>
                            <h1 className="h1 mb-4">Premium Access Required</h1>
                        </div>
                        <Row>
                            <Col>

                                <div className="mb-4" style={{textAlign: 'center'}}><a
                                    href={this.redirectLink('user/subscription', state)} className="btn btn-primary-blue w-100"><i
                                    style={{marginRight: '4px'}} className="fa fa-lock" aria-hidden="true"></i>
                                    Please Upgrade To Contact Seller

                                </a></div>
                                <p className="mb-4" style={{textAlign: 'center'}}><a
                                    href={this.redirectLink('user/subscription', state)}>Learn More</a></p>

                            </Col>

                        </Row>

                    </ModalBody>
                </Modal>


                <Modal isOpen={state.assignListingModal}
                       toggle={() => this.setState({assignListingModal: !state.assignListingModal})}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({assignListingModal: !state.assignListingModal})}>
                        Assign to project
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label htmlFor="" md={4}>Assign to:</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Select
                                        className="w-100"
                                        onChange={(p) => {
                                            if (p.value === 0) {

                                                this.setState({assignListingModal: false});
                                                this.setState({newProjectModal: true});

                                            } else {
                                                this.setState({assignListingTo: p});
                                            }
                                        }}
                                        options={[{label: '+ Create New', value: 0}, {
                                            label: 'Unassigned',
                                            value: null
                                        }].concat(state.savedSearchProjects.map(p => {
                                            return {
                                                label: p.name,
                                                value: p.id
                                            }
                                        }))}
                                        value={state.assignListingTo}
                                    />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.onClickAssignListing}>Assign</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={state.newProjectModal} toggle={() => this.setState({newProjectModal: !state.newProjectModal})}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({newProjectModal: !state.newProjectModal})}>
                        Create a new Buy-Side Client or Project
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup row>
                            <Label md={12}>Add as many specific searches as you'd like for each acquisition client or project you
                                have</Label>
                            <Label htmlFor="" md={4}>Name</Label>
                            <Col md={7}>
                                <InputGroup>
                                    <Input onKeyPress={e => {
                                        if (e.charCode === 13) {
                                            onCreateNewProject();
                                        }
                                    }} value={state.projectName} onChange={(e) => this.setState({projectName: e.target.value})}
                                           type="text"/>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={onCreateNewProject}>Create</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={state.partnerModal} toggle={() => this.setState({partnerModal: !state.partnerModal})}
                       className="modal-dialog-centered delete-item" keyboard={true} backdrop="static">
                    <ModalHeader toggle={() => this.setState({partnerModal: !state.partnerModal})}>
                        {state.partnerServiceName}
                    </ModalHeader>
                    <ModalBody>
                        {state.loggedIn ? <p>{state.partnerBody}</p> :
                            <>
                                <p>{state.partnerBody}</p>
                                <FormGroup row>
                                    <Label htmlFor="" md={4}>First Name <span style={{color: 'red'}}>*</span></Label>
                                    <Col md={7}>
                                        <InputGroup>
                                            <Input required value={state.partnerName}
                                                   onChange={(e) => this.setState({partnerName: e.target.value})} type="text"/>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="" md={4}>Last Name <span style={{color: 'red'}}>*</span></Label>
                                    <Col md={7}>
                                        <InputGroup>
                                            <Input required value={state.partnerLastName}
                                                   onChange={(e) => this.setState({partnerLastName: e.target.value})} type="text"/>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="" md={4}>Email <span style={{color: 'red'}}>*</span></Label>
                                    <Col md={7}>
                                        <InputGroup>
                                            <Input required value={state.partnerEmail}
                                                   onChange={(e) => this.setState({partnerEmail: e.target.value})} type="text"/>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                {state.partnerModalErrorMessage && <span className="error">Please fill out all required fields</span>}
                            </>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={this.partnerClick}>Submit</Button>
                    </ModalFooter>
                </Modal>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(DealPage);
